import React from 'react';
import styles from './style.module.scss';

interface HomeTextProps {}

const HomeText: React.FC<HomeTextProps> = ({}) => {
  return (
    <>
      <section className={`${styles['news-section']}`}>
        <div className="container-1240 px-sm-0">
          <div className={`${styles['news-inner']}`}>
            <div className={`${styles['wdRow']} wdRow`}>
              <div className="wdCol-lg-4 wdCol-md-4">
                <p className={styles['headingNew']}>
                  Wooden Street In the News
                  <span className={styles['subheading']}>
                    See where we’ve been making the headlines
                  </span>
                </p>
                <div className={styles['media-images']}>
                  <img
                    src="images/home-new1/media-partner-logo.jpg?v=1"
                    data-src="images/home-new1/media-partner-logo.jpg?v=1"
                    alt="Media Partner"
                    data-was-processed="true"
                  />
                </div>
                <div className={`${styles['news-button']}`}>
                  <a
                    href="media"
                    className={styles['view-link']}
                    target="_blank"
                    rel="noreferrer"
                  >
                    View All Media
                  </a>
                </div>
              </div>
              <div className={`wdCol-lg-8 wdCol-md-8`}>
                <div className={`${styles['news-right-card']}`}>
                  <a
                    className={styles['media-block']}
                    href="https://www.facebook.com/?next=https%3A%2F%2Fm.facebook.com%2Fcnbcawaaz%2Fvideos%2F704542183372935%2F&refsrc=deprecated&_rdr"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <figure>
                      <img
                        src="images/home-new1/media-1.jpg"
                        data-src="images/home-new1/media-1.jpg"
                        alt=""
                        data-was-processed="true"
                      />
                    </figure>
                    <p className={styles['media-head']}>
                      WoodenStreet Covered by CNBC Aawaz
                    </p>
                    <p className={styles['media-subhead']}>
                      The interviews of the founders explaining our vision,
                      unique customization model and an extensive omni-channel
                      expansion across India.
                    </p>
                  </a>
                  <a
                    className={styles['media-block']}
                    href="https://yourstory.com/people/lokendra-singh-20?origin=awards"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <figure>
                      <img
                        src="images/home-new1/media-2.jpg"
                        data-src="images/home-new1/media-2.jpg"
                        alt=""
                        data-was-processed="true"
                      />
                    </figure>
                    <p className={styles['media-head']}>
                      Featured in YourStory 100 Digital Influencers of 2020
                    </p>
                    <p className={styles['media-subhead']}>
                      Our co-founder, Lokendra Singh Ranawat is featured as the
                      notable influencers of the year 2020.
                    </p>
                  </a>
                </div>
                <div className={styles.viewmorebtndiv}>
                  <a
                    href="#"
                    className={`${styles['view-all-furni']} btn view-all-btn`}
                  >
                    View All Media
                  </a>
                </div>
              </div>
            </div>

            {/* <a
          href="media"
          className={styles['cat_btn']}
          target="_blank"
          rel="noreferrer"
        >
          View All Media
        </a> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeText;
